import React from 'react'
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Button, Navbar, Nav, } from 'react-bootstrap';

export default function Header() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div className='header'>
                <div className='lg-container'>
                    <Navbar collapseOnSelect expand="lg" >
                        <Navbar.Brand href="https://www.airtravelclaim.com/">
                            <img src={require('../images/logo.png')} className='logo' alt="" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className=" ms-auto">
                                <Nav.Link href="https://www.airtravelclaim.com/">Home</Nav.Link>
                                <Nav.Link href="https://www.airtravelclaim.com/faqs/">FAQ’s</Nav.Link>
                                <Nav.Link href="https://www.airtravelclaim.com/about-us/">About Air Travel Claim</Nav.Link>
                                <Nav.Link href="https://www.airtravelclaim.com/contact-us/">Contact us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
            <div className='Mobile-header'>
                <div className='logo'>
                    <img src={require('../images/logo.png')} alt="" />
                </div>
                <div className='sidenav-btn' onClick={handleShow}>
                    <i className="fa-solid fa-bars"></i>
                </div>
            </div>
            <Offcanvas show={show} onHide={handleClose} placement="end" backdrop="static">
                <Offcanvas.Body>
                    <Button className='close' onClick={handleClose}>
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                    <div className='sidenav-content'>
                        <div className='navbar'>
                            <div className='nav-item'>
                                <a href='https://www.airtravelclaim.com/' className='nav-link'>Home</a>
                            </div>
                            <div className='nav-item'>
                                <a href='https://www.airtravelclaim.com/faqs/' className='nav-link'>FAQ’s</a>
                            </div>
                            <div className='nav-item'>
                                <a href='https://www.airtravelclaim.com/about-us/' className='nav-link'>About Air Travel Claim</a>
                            </div>
                            <div className='nav-item'>
                                <a href='https://www.airtravelclaim.com/contact-us/' className='nav-link'>Contact us</a>
                            </div>
                        </div>
                        <div className='bottom-btn'>
                            <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                Claim Now
                            </Button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
