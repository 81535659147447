import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';

export default function Searchform() {
    const [queryParams, setQueryParams] = useState([]);
    const [departureTerm, setDepartureTerm] = useState('');
    const [departureTermVal, setDepartureTermVal] = useState('');
    const [finalDestinationTerm, setFinalDestinationTerm] = useState('');
    const [finalDestinationTermVal, setFinalDestinationTermVal] = useState('');
    const [departureOptions, setDepartureOptions] = useState([]);
    const [finalDestinationOptions, setFinalDestinationOptions] = useState([]);

    const handleDepartureChange = async (event) => {
        const term = event.target.value;
        setDepartureTerm(term);

        if (term.length > 2) {
            try {
                const response = await axios.post(
                    'https://flights.tanantech.co.uk/1.0/airport_search',
                    { destination: term },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                const data = response.data.data.map((value) => ({
                    label: `${value.airportName}, ${value.city}, ${value.country}`,
                    value: value.airportCode
                }));

                setDepartureOptions(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setDepartureOptions([]);
        }
    };

    const handleFinalDestinationChange = async (event) => {
        const term = event.target.value;
        setFinalDestinationTerm(term);

        if (term.length > 2) {
            try {
                const response = await axios.post(
                    'https://flights.tanantech.co.uk/1.0/airport_search',
                    { destination: term },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                const data = response.data.data.map((value) => ({
                    label: `${value.airportName}, ${value.city}, ${value.country}`,
                    value: value.airportCode
                }));

                setFinalDestinationOptions(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFinalDestinationOptions([]);
        }
    };

    const handleDepartureSelect = (option) => {
        setDepartureTerm(option.label);
        setDepartureTermVal(option.value);
        setDepartureOptions([]);
        // Do whatever you want with the selected value
    };

    const handleFinalDestinationSelect = (option) => {
        setFinalDestinationTerm(option.label);
        setFinalDestinationTermVal(option.value);
        setFinalDestinationOptions([]);
        // Do whatever you want with the selected value
    };

    useEffect(() => {
        // Function to retrieve query parameters from the current URL
        const getQueryParams = () => {
            const params = new URLSearchParams(window.location.search);
            return Array.from(params.entries()); // Returns an array of [key, value] pairs
        };

        // Call the function to get query parameters and store them in state
        setQueryParams(getQueryParams());
    }, []);
    return (
        <Form action={`https://app.airtravelclaim.com/?${queryParams}`} method="get">
            {queryParams.map(([key, value]) => (
                <input key={key} type="hidden" name={key} value={value} />
            ))}
            <Form.Group className='form-group'>
                <img src={require('../images/take-off-icn.png')} className='form-icon' alt="" />
                <Form.Control type="text"
                    value={departureTerm}
                    onChange={handleDepartureChange}
                    placeholder="Departure Airport" />
                <Form.Control type="hidden" name="departure"
                    value={departureTermVal}
                />
                <ul className='search-result'>
                    {departureOptions.map((option, index) => (
                        <li key={index} onClick={() => handleDepartureSelect(option)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            </Form.Group>
            <Form.Group className='form-group'>
                <img src={require('../images/land-icn.png')} className='form-icon' alt="" />
                <Form.Control type="text"
                    value={finalDestinationTerm}
                    onChange={handleFinalDestinationChange}
                    placeholder="Final Destination Airport" />
                <Form.Control type="hidden" name="arrival"
                    value={finalDestinationTermVal}
                />
                <ul className='search-result'>
                    {finalDestinationOptions.map((option, index) => (
                        <li key={index} onClick={() => handleFinalDestinationSelect(option)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            </Form.Group>
            <Form.Group className='form-group'>
                <Button type='submit' variant="success" className='search-btn'>
                    Check Your Compensation
                </Button>
            </Form.Group>
        </Form>
    )
}
