import React from 'react'
import { Button, Accordion } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../components/Header';
import Searchform from '../components/Searchform';
import Footer from '../components/Footer';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GoogleReviews from '../js/google-reviews/GoogleReviews';

AOS.init();
const options = {
    responsiveClass: true,
    nav: true,
    autoplay: false,
    navText: ["<i className='fa-solid fa-arrow-left'></i>", "<i className='fa-solid fa-arrow-right'></i>"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },

        991: {
            items: 3,
        },
        1200: {
            items: 4,

        }
    },
};

export default function Index() {

    return (
        <div>

            {/* Header */}
            <Header />
            {/* Header */}

            {/* Hero Section */}
            <div className='hero-section'>
                <div className='lg-container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='content-section' data-aos="fade-up" data-aos-offset="100"
                                data-aos-delay="400">
                                <h1>
                                    Have you had your flight delayed or cancelled?
                                </h1>
                                <h1>
                                    GET UP TO £1,040
                                </h1>
                                <h1 className='per-couple'>
                                    PER COUPLE,
                                </h1>
                                <h2>
                                    NO WIN NO FEE!
                                </h2>
                                <h2>
                                    START TODAY
                                </h2>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-section' data-aos="fade-up" data-aos-offset="100"
                                data-aos-delay="400">
                                <Searchform />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='google-reviews'>
                    <a href="https://www.google.com/search?hl=en-GB&gl=uk&q=Air+Travel+Claim,+84+Salop+St,+Wolverhampton+WV3+0SR&ludocid=8443520805209278641&lsig=AB86z5WGOvmGc5_DXmJyceuiIZE_#lrd=0x48709ba162bd29b9:0x752d696fc81e88b1,1"
                    target='_blank' rel='noreferrer'>
                        <span className='nowrap'>
                            <strong>EXCELLENT</strong>
                        </span>
                        <span className='stars'>
                            <i className="fa-sharp fa-solid fa-star"></i>
                            <i className="fa-sharp fa-solid fa-star"></i>
                            <i className="fa-sharp fa-solid fa-star"></i>
                            <i className="fa-sharp fa-solid fa-star"></i>
                            <i className="fa-sharp fa-solid fa-star"></i>
                        </span>
                        <span className='google'>
                            <img src={require('../images/google.png')} alt="" />

                        </span>
                    </a>
                </div>
            </div>
            {/* Hero Section */}

            {/* features Section */}
            <div className='features-section'>
                <div className='lg-container'>
                    <div className='center-heading'>
                        <h4>
                            Free to Check. Only takes 2 minutes.
                        </h4>
                        <p className='rights'>
                            Protecting the rights of airline passengers: UK261
                            <img src={require('../images/12.png')} alt="" />
                            & EU261
                            <img src={require('../images/11.png')} alt="" />
                        </p>
                    </div>
                    <div className='home-features-box'>
                        <div className='home-features-item' data-aos="fade-up" data-aos-offset="100"
                            data-aos-delay="200">
                            <img src={require('../images/trusted-icn.png')} alt="" />
                            <span>Free flight check</span>
                        </div>
                        <div className='home-features-item' data-aos="fade-up" data-aos-offset="100"
                            data-aos-delay="400">
                            <img src={require('../images/claim-upto-icn.png')} alt="" />
                            <span>Claim up to £1,040 per couple</span>
                        </div>
                        <div className='home-features-item' data-aos="fade-up" data-aos-offset="100"
                            data-aos-delay="600">
                            <img src={require('../images/ticket-icn.png')} alt="" />
                            <span>No win, No fee</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* features Section */}

            {/* We Help Section */}
            <div className='we-help-section'>
                <div className='lg-container'>
                    <div className='center-heading'>
                        <h1>
                            We help you claim what is rightfully yours!
                        </h1>
                        <h2>
                            Most airlines avoid paying your compensation which is in addition to your ticket refund!
                        </h2>
                        <p>
                            Under regulations UK261 and EU261 you could be entitled to up to £1,040 per couple for a flight disruption.
                        </p>
                        <p>
                            We fight on your behalf!
                        </p>
                        <p>
                            It’s no win no fee! Claim for the last 6 years.
                        </p>
                    </div>
                    <div className='feartures-slider'>
                        <OwlCarousel loop={true} autoplay={true} owl-dot={true} {...options}>
                            <div className='owl-row'>
                                <div className='feartures-card'>
                                    <img src={require('../images/features-icn1.png')} alt="" />
                                    <h4>
                                        Flight cancelled?
                                    </h4>
                                    <div className='line'></div>
                                    <span>
                                        Flight cancelled within 14 days of departure?
                                    </span>
                                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                        Start Your Claim
                                    </Button>
                                </div>
                            </div>
                            <div className='owl-row'>
                                <div className='feartures-card'>
                                    <img src={require('../images/features-icn2.png')} alt="" />
                                    <h4>
                                        Flight delayed?
                                    </h4>
                                    <div className='line'></div>
                                    <span>
                                        Was your flight delayed by 3 hours or more?
                                    </span>
                                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                        Start Your Claim
                                    </Button>
                                </div>
                            </div>
                            <div className='owl-row'>
                                <div className='feartures-card'>
                                    <img src={require('../images/features-icn3.png')} alt="" />
                                    <h4>
                                        Denied boarding?
                                    </h4>
                                    <div className='line'></div>
                                    <span>
                                        Did you turn up on time but your flight was full?
                                    </span>
                                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                        Start Your Claim
                                    </Button>
                                </div>
                            </div>
                            <div className='owl-row'>
                                <div className='feartures-card'>
                                    <img src={require('../images/features-icn4.png')} alt="" />
                                    <h4>
                                        Connection problems?
                                    </h4>
                                    <div className='line'></div>
                                    <span>
                                        Did you miss a connecting flight?
                                    </span>
                                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                        Start Your Claim
                                    </Button>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            {/* We Help Section */}

            {/* Video Section */}
            <div className='video-section'>
                <div className='lg-container'>
                    <div className='center-heading'>
                        <h2>
                            If you have suffered a flight disruption in the last 6 years, then under regulations UK261 and EU261 you could be eligible to claim up to £1,040 in compensation per couple.
                        </h2>
                    </div>
                    <div className='video-content' data-aos="fade-up" data-aos-offset="100"
                        data-aos-delay="200">
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='content'>
                                    <h3>
                                        Get up to £1,040 <small>per couple</small> in compensation
                                    </h3>
                                    <p>
                                        You can claim for the last 6 years.
                                    </p>
                                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                        Start Your Claim
                                    </Button>
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className="advantage-claim-video">
                                    <iframe src="https://player.vimeo.com/video/844961596?h=dca6b5bfe0" title='claim-video' width="717" height="408" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Video Section */}

            {/* Compensation Section */}
            <div className='compensation-section'>
                <img src={require('../images/ATC_917x596a.png')} alt="" />
                <div className='lg-container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='content-area' data-aos="fade-up" data-aos-offset="100"
                                data-aos-delay="200">
                                <h1>
                                    When things don’t go according to plan with your flight, our specialists are here to help you.
                                </h1>
                                <p>
                                    If you have suffered a flight delay, had your flight cancelled within 14 days of departure, had your flight re-routed, or you were denied boarding, you may be able to claim up to £1,040 per couple in flight compensation. Airlines have been cancelling thousands of flights. Have you been affected? We can help you get compensation!
                                </p>
                                <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                    Start Your Claim
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Compensation Section */}

            {/* Benefits Section */}
            <div className='benefits-section'>
                <div className='lg-container'>
                    <div className='center-heading'>
                        <h1>
                            Benefits of Using Air Travel Claim
                        </h1>
                    </div>
                    <div className='benefits-content'>
                        <div className='row justify-content-center'>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="200">
                                    <img src={require('../images/1.png')} alt="" />
                                    <p>
                                        No Win – No Fee & no upfront cost
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="400">
                                    <img src={require('../images/2.png')} alt="" />
                                    <p>
                                        Simple online form to start your claim
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="600">
                                    <img src={require('../images/3.png')} alt="" />
                                    <p>
                                        Free to check
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="200">
                                    <img src={require('../images/4.png')} alt="" />
                                    <p>
                                        Straightforward claim process
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="400">
                                    <img src={require('../images/5.png')} alt="" />
                                    <p>
                                        Technology to confirm the validity of your claim
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="600">
                                    <img src={require('../images/6.png')} alt="" />
                                    <p>
                                        Experienced Specialists
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="200">
                                    <img src={require('../images/7.png')} alt="" />
                                    <p>
                                        Court fees are paid on your behalf
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="400">
                                    <img src={require('../images/8.png')} alt="" />
                                    <p>
                                        A formal letter of claim to the airline
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='b-card' data-aos="fade-up" data-aos-offset="100"
                                    data-aos-delay="600">
                                    <img src={require('../images/9.png')} alt="" />
                                    <p>
                                        Professionally drafted particulars of claim
                                        and court documents
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='center-heading pt-4'>
                                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                                        Check Your Compensation
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Benefits Section */}

            {/* FAQ Section */}
            <div className='faq-section'>
                <div className='lg-container'>
                    <div className='faq-content'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='content-area'>
                                    <h1>
                                        Let’s answer some of your questions.
                                    </h1>
                                    <p>
                                        Travellers banking on timely flights for holidays or homecomings may encounter delays or cancellations. However, passengers can find solace in the broad compensation rights available for such disruptions. For further clarity on these compensation rights, please visit our Frequently Asked Questions (FAQs) page
                                    </p>
                                    <Button variant="success" href='https://www.airtravelclaim.com/faqs/' className='claim-btn'>
                                        More FAQ's
                                    </Button>
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>How much compensation am I entitled to for my flight?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                The amount of the compensation claim depends on the length of the flightpath. How much you paid for the ticket is irrelevant for the compensation.
                                            </p>
                                            <p>
                                                Under the UK Regulations for short-haul flights (less than 1,500 kilometres), you are entitled to compensation of £220 per person or up to £440 per couple.
                                            </p>
                                            <p>
                                                For medium-haul flights (up to 3,500 kilometres) you are entitled to £350 or £700 per couple.
                                            </p>
                                            <p>
                                                For long-haul flights (over 3,500 kilometres), the possible compensation amount increases to £520 or £1,040 per couple.
                                            </p>
                                            <p>
                                                The figures are slightly different where claims fall under EU rather than UK jurisdiction but are broadly similar.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What am I entitled to in event of a flight delay?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                If your flight arrives at its destination with a delay of more than 3 hours, you could potentially be eligible for compensation, provided that the airline is responsible for the delay. Additionally, if the delay results in long waiting times at the airport, the airline is required to provide you with complimentary drinks and snacks.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>What are my rights?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                People who want to start their long-awaited holiday or return home after their trip usually rely on flights taking off as planned. Unfortunately, it happens again and again that flights are delayed, cancelled or the connecting flight is missed.
                                            </p>
                                            <p>
                                                The good news for affected passengers: is that, travellers have extensive rights under Air Passenger Rights Regulations and may be entitled to a ticket refund and compensation. This is at least some recompense for the inconvenience caused.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* FAQ Section */}

            {/* Customers Say Section */}
             {/* <div className='customers-say-section'>
                <div className='lg-container'>
                    <div className='center-heading'>
                        <h1>
                            What some of our customers say:
                        </h1>
                    </div>
                    <div className='customers-say-content'>
                        <div className='row'>
                            <GoogleReviews />
                            <div className='col-lg-4 col-md-6' data-aos="fade-up" data-aos-offset="100"
                                data-aos-delay="200">
                                <div className='review'>
                                    <img src={require('../images/New1_2-266x300.png')} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6' data-aos="fade-up" data-aos-offset="100"
                                data-aos-delay="400">
                                <div className='review'>
                                    <img src={require('../images/new2_2-266x300.png')} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12' data-aos="fade-up" data-aos-offset="100"
                                data-aos-delay="600">
                                <div className='review'>
                                    <img src={require('../images/New3_2-266x300.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           Customers Say Section */}

            {/* Delays Section */}
            <div className='delays-section'>
                <div className='center-heading' data-aos="fade-up" data-aos-offset="100"
                    data-aos-delay="200">
                    <h1>
                        Flight delays and cancellations are more common than you’d think and affect thousands of people.
                    </h1>
                    <Button variant="success" href='https://app.airtravelclaim.com/START_DESTINATION/?=' className='claim-btn'>
                        Start Your Claim
                    </Button>
                </div>
            </div>
            {/* Delays Section */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

        </div >
    );
}
